@tailwind base;
@tailwind components;
@tailwind utilities;

#root {
  height: 100%;
}

/* TOP MENU VISIBILITY */

body.dashboard-route #page-top-header {
  display: none;
}

body:not(.dashboard-route) #main-layout {
  padding-top: 6rem;
}

/* TAILWIND COMPONENTS */

@layer components {

  .button-green {
    @apply mt-8 bg-amz3green-100 hover:bg-amz3green-50 text-amz3green-300 rounded-md px-4 py-2;
  }

  .button-green-light {
    @apply mt-8 bg-amz3green-50 hover:bg-white text-amz3green-300 rounded-md px-4 py-2;
  }

  .button-green-outline {
    @apply px-4 py-2 rounded-lg border-amz3green-100 border-solid border-[1px] text-amz3green-100
    hover:border-amz3green-50 hover:text-amz3green-50;
  }

  /* CATALOG */
  .catalog-mapbox-button {
    @apply bg-amz3green-300 text-amz3green-50 rounded-full p-4 hover:scale-105 active:bg-black;
  }

  .catalog-tabgroup {
    @apply grid grid-cols-3 lg:grid-cols-2 text-center;
  }

  .catalog-tabgroup .catalog-tab {
    @apply relative w-full h-full p-4 lg:px-6 text-sm lg:text-base hover:bg-black hover:bg-opacity-20 uppercase;
  }

  .catalog-tabgroup li.active .catalog-tab {
    @apply text-amz3green-100 font-semibold lg:text-inherit;
  }
  .catalog-tabgroup li.active .catalog-tab::after {
    @apply block absolute h-[2px] bg-amz3green-100 bottom-0 left-0 w-full lg:hidden;
    content: '';
  }

  .catalog-tabgroup li.active-desktop .catalog-tab {
    @apply lg:text-amz3green-100 lg:font-semibold;
  }
  .catalog-tabgroup li.active-desktop .catalog-tab::after {
    @apply block absolute h-[2px] lg:bg-amz3green-100 bottom-0 left-0 w-full;
    content: '';
  }
}

/* OVERRIDES */

.mapboxgl-map {
  font-family: 'Sora';
}
.mapboxgl-popup-content {
  @apply rounded-[20px] bg-[#161616];
}
.mapboxgl-popup-anchor-bottom .mapboxgl-popup-tip {
  border-top-color: #161616;
}

.mapboxgl-popup-close-button {
  color: #fff;
  right: 15px;
  top: 10px;
}